import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Page two" />
    <h1>About</h1>
    <p>We're StagedApp!</p>
    <p>Our goal is to make it easier for bands to play better gigs.</p>
    <p>We'll be launching our app in the coming weeks.</p>
    <p>In the meantime, stay tuned for updates by joining our mailing list and following us on instagram.</p>
    <div>
      IG: <a href="https://www.instagram.com/staged_app_nyc/">StagedApp</a>
    </div>
    <Link to="/">Home</Link>
  </Layout>
)

export default SecondPage
